import { Alert, Avatar, Col, Layout, Menu, Rate, Row, Spin } from "antd";
import React, { ReactElement } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  adminServices,
  getServicePaths, 
  isAllowedService,
} from "../../../utils/services";
import { SIDEBAR_COLLAPSE } from "../../../redux/auth/authType";
import { getImgUrl } from "../../../utils";
import LogoImg from "../../../images/logo.png";
import IconImg from "../../../images/favicon.svg";
import noImage from "../../../images/noImage.png";
const { Sider } = Layout;
const { SubMenu } = Menu;

const MainSidebar = (): ReactElement => {
  const { sidebarCollapse, type, shopInfo, merchantInfo, userType } =
    useSelector((state) => (state as any)?.authReducer);
  const dispatch = useDispatch();
  const pathname = window.location.pathname;
  const [loading, setLoading] = React.useState<boolean>(false);
  const setCollapsed = (value: boolean) => {
    dispatch({
      type: SIDEBAR_COLLAPSE,
      payload: {
        sidebarCollapse: value,
      },
    });
  };

  return (
    <>
      <Sider
        width={300}
        collapsible
        collapsed={sidebarCollapse}
        onCollapse={(value) => {
          setCollapsed(value);
        }}
      >
        <div className="logo sticky top-0 z-10">
          <Link to="/">
            <img
              src={sidebarCollapse ? IconImg : LogoImg}
              alt="jachai parcel"
              className="logoImg"
            />
          </Link>
        </div>

        {!sidebarCollapse && (
          <div className="bg-transparent text-white shadow-gray-200 border-b mb-4">
            <div className="">
              <div className="max-h-40 h-40 overflow-hidden">
                <img
                  src={
                    merchantInfo?.merchantPersonalDetail?.identificationDocumentUrl ||
                    noImage
                  }
                  alt=""
                  className="object-cover min-h-full w-full object-center"
                />
              </div>

              <div className="px-4 pb-2">
                <div className="-mt-8 xl:-mt-8">
                  <Avatar
                    size={70}
                    shape="circle"
                    src={getImgUrl(
                      merchantInfo?.merchantPersonalDetail?.profilePicture ||
                        noImage
                    )}
                    className="mb-5 w-20 h-20 shadow-lg border-2 border-white bg-gray-50"
                  />
                </div>
                <div>
                  <h2 className="text-lg text-white font-bold">
                    {
                      merchantInfo?.merchantPersonalDetail?.name?.toUpperCase()}
                  </h2>

                  {/* <ul className="space-y-1">
                  {merchantInfo?.balance && (
                    <li className="flex items-center text-sm font-normal text-gray-500">
                      <svg
                        className="mr-2 w-4 h-4 text-gray-600"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
                          clipRule="evenodd"
                        ></path>
                        <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z"></path>
                      </svg>
                      &#2547;
                      {parseInt(merchantInfo?.balance) || 0}
                    </li>
                  )}
                </ul> */}

                  <Rate
                    value={shopInfo?.rating || merchantInfo?.rating || 1}
                    disabled
                  />
                  <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-1 py-0.5 rounded-full dark:bg-blue-200 dark:text-blue-800 ml-2">
                    {
                      merchantInfo?.numberOfRating ||
                      0}
                  </span>
                </div>
                <div className="mb-2 text-sm font-medium bg-gray-300 text-slate-800 rounded-md px-2 py-0.5 mt-2">
                  {
                    merchantInfo?.mobileNumber?.replace("+88", "")}
                </div>
                <div className="mb-2 text-sm font-medium bg-gray-300 text-slate-800 rounded-md px-2 py-5 mt-2">
                  <Row>
                    <Col span={16}>
                      <h2 className="text-lg font-bold">Jachai.com Membership</h2>
                      <p className="text-slate-400 text-xs">
                        Explore US Tk 10 of wih a new seller and more benefits
                      </p>
                    </Col>
                    <Col span={8}>
                      <div
                        className={
                          "hover:from-pink-500 hover:to-yellow-500 text-white font-bold py-1 mt-6 px-4 rounded-full shadow-md bg-gradient-to-r from-indigo-500 via-pink-500 to-red-500"
                        }
                      >
                        <Link to="/membership">
                        Explore
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        )}

        <Menu
          defaultOpenKeys={[getServicePaths(pathname)]}
          mode="inline"
          theme="dark"
        >
          {adminServices?.map((service, i) => {
            if (service?.sub && service?.sub?.length) {
              if (
                service?.sub?.filter((item) => {
                  const excludeIcon = item;
                  return excludeIcon?.icon && isAllowedService(item, userType);
                })?.length === 1 &&
                service?.sub?.find((item) => {
                  return item.isParent;
                })
              ) {
                const actualPath = `${service?.path}${service?.sub?.[0]?.path}`;

                return (
                  <Menu.Item
                    key={service?.path}
                    icon={<FontAwesomeIcon icon={service?.icon} />}
                    className={
                      pathname === actualPath && service?.path ? "active" : ""
                    }
                  >
                    <Link to={actualPath}>
                      {type == "JC_FOOD"
                        ? service?.name
                            ?.replace("Shops", "Restaurants")
                            ?.replace("Product", "Menu")
                        : service?.name}
                    </Link>
                  </Menu.Item>
                );
              } else {
                return (
                  service?.sub?.find((item) =>
                    isAllowedService(item, userType)
                  ) && (
                    <SubMenu
                      key={service?.path}
                      icon={<FontAwesomeIcon icon={service?.icon} />}
                      title={service?.name?.replace("Shops", "Restaurants")}
                    >
                      {service?.sub?.map((sub) => {
                        const actualPath = `${service?.path}${sub?.path}`;
                        const excludeIcon = sub;
                        return (
                          !actualPath.includes(":") &&
                          excludeIcon?.icon &&
                          isAllowedService(sub, userType) && (
                            <Menu.Item
                              key={actualPath}
                              icon={<FontAwesomeIcon icon={sub?.icon} />}
                              className={
                                pathname === actualPath ? "active" : ""
                              }
                            >
                              <Link to={actualPath}>{sub?.name}</Link>
                            </Menu.Item>
                          )
                        );
                      })}
                    </SubMenu>
                  )
                );
              }
            } else {
              return (
                isAllowedService(service, userType) && (
                  <Menu.Item
                    key={service?.path}
                    icon={<FontAwesomeIcon icon={service?.icon} />}
                    className={pathname === service?.path ? "active" : ""}
                  >
                    <Link to={service?.path as any}>{service?.name}</Link>
                  </Menu.Item>
                )
              );
            }
          })}
        </Menu>
        
      </Sider>
    </>
  );
};

export default MainSidebar;
