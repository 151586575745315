import {
  BarcodeOutlined,
  EditOutlined,
  EyeOutlined,
  FileDoneOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AutoComplete,
  Avatar,
  Button,
  Empty,
  Form,
  Input,
  Select,
  Spin,
} from "antd";
import axios from "axios";
import BreadCrumb from "../Layouts/Breadcrumb";
import { getPage, getParamValue } from "../../utils";
import { useLocation, useNavigate, useParams } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { SHOW_SEARCHBAR } from "../../redux/search/searchType";
import React from "react";
import { Link } from "react-router-dom";
import { isAllowedService } from "../../utils/services";
import {
  AddBtn,
  Loader,
  LoaderFull,
  Pagination,
  TreeSelectTwo,
} from "../common";
 
const RequestedProductList = ({
  inShop = false,
  getShopId,
}: {
  inShop?: boolean;
  getShopId?: string;
}): ReactElement => {
  const liveUrl = `${process.env.REACT_APP_LIVE_URL}`;
  const { token, type, shopId, merchantId } = useSelector(
    (state) => (state as any)?.authReducer
  );  
  const status	 = "REQUESTED";
  const [showSearch, setShowSearch] = useState(true);
  const [form] = Form.useForm();
  const route = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [confirmLoading, setConfirmLoading] = useState(undefined);
  const [key, setKey] = useState("");

  const [categoryId, setCategoryId] = useState<any>(``);
  const [brandId, setBrandId] = useState<any>(``);
  const [campaignId, setCampaignId] = useState<any>();
  const [isActive, setIsActive] = useState<any>(undefined);
  const [selectedProductForEdit, setSelectedProductForEdit] =
    useState<any>(undefined);

  const [productData, setProductData] = useState<any>({
    loading: false,
    data: null,
  });

  const [productOptions, setProductOptions] = useState({
    loading: false,
    list: [],
  });

  const [shopOptions, setShopOptions] = useState({
    loading: false,
    list: [],
  });

  const [brandOptions, setBrandOptions] = useState({
    loading: false,
    list: [],
  });

  useEffect(() => {
    setCampaignId((route as any).campaignId || "");
  }, [route]);

  useEffect(() => {
    setBrandId((route as any)?.brandId || "");
  }, [route]);

  const getProducts = useCallback(async () => {
      setProductData({ loading: true, data: null });

      const encodedUrl = `${process.env.REACT_APP_CATALOG_READER_API}`;
      let url =
        `?shopId=${getShopId}` +
        (categoryId ? `&categoryId=${categoryId}` : ``) +
        (brandId ? `&brandId=${brandId}` : ``) +
        (status ? `&status=${status}` : ``) +
        (page
          ? `&page=${
              page == productData.data?.currentPageNumber ? 0 : page || 0
            }`
          : ``) +
        (limit ? `&limit=${limit}` : ``);
      url = encodeURI(url);
      axios
        .get(`${encodedUrl}/merchant/product${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setProductData({ loading: false, data: res.data });
        })
        .catch((err) => {
          setProductData({ loading: false, data: [] });
          console.error("Type: Error", err);
        });
    
  }, [
    getShopId,
    categoryId,
    merchantId,
    brandId,
    campaignId,
    isActive,
    key,
    page,
    limit,
  ]);

  const getProductOptions = useCallback(
    async (key: any) => {
      setProductOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      let url =
        `?type=${type}&shopId=${getShopId}&page=0&limit=20` +
        (key ? `&name=${key}` : ``) +
        (categoryId ? `&categoryId=${categoryId}` : ``) +
        (brandId ? `&brandId=${brandId}` : ``) +
        (campaignId ? `&campaignId=${campaignId}` : ``) +
        (isActive ? `&isActive=${isActive}` : ``);
      url = encodeURI(url);

      return axios
        .get(`${encodedUri}/merchant/product${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setProductOptions({
            loading: false,
            list: res.data?.keys?.map((product: { name: any }) => ({
              label: product.name,
              value: product.name,
            })),
          });
        })
        .catch((err) => {
          setProductOptions({ loading: false, list: [] });
          console.error("Products: Error", err);
        });
    },
    [getShopId, categoryId, brandId, campaignId, isActive, key, page, limit]
  );

  const getShopOptions = useCallback(
    async (key: any) => {
      setShopOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      return axios
        .get(
          `${encodedUri}/shop/admin/search?type=${type}&page=0&limit=20` +
            (key ? `&key=${key}` : ``) +
            (merchantId ? `&merchantId=${merchantId}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setShopOptions({
            loading: false,
            list: res.data?.shops?.map((shop: any) => ({
              label: shop?.name,
              value: shop?.id,
            })),
          });
        })
        .catch((err) => {
          setShopOptions({ loading: false, list: [] });
          console.error("Categories: Error", err);
        });
    },
    [type]
  );

  const getBrandsOptions = useCallback(async (key?: string) => {
    setBrandOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    const res = await axios.get(
      `${encodedUri}/brand/search?type=${type}&page=0&limit=20` +
        (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setBrandOptions({
      loading: false,
      list: res?.data?.brands?.map((brand: any) => ({
        label: brand.name,
        value: brand.id,
      })),
    });
  }, []);

  // ************************

  // ************************
  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "product") getProductOptions(value);
        else if (field === "shop") getShopOptions(value);
        else if (field === "brand") getBrandsOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getProductOptions, getShopOptions, getBrandsOptions]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  useEffect(() => {
    if (showSearch) {
      getProductOptions("");
      getShopOptions("");
      getBrandsOptions("");
    }
  }, [showSearch]);

  const reseAllFieldData = () => {
    form.resetFields();
    setKey("");
    setCategoryId("");
    setBrandId("");
    setCampaignId("");
    setIsActive(undefined);
  };

  function UnLoadWindow() {
    dispatch({
      type: SHOW_SEARCHBAR,
      payload: {
        shopId: shopId,
        categoryId: categoryId,
        brandId: brandId,
        campaignId: campaignId,
      },
    });
  }

  const initBeforeUnLoad = (showExitPrompt: any) => {
    window.onbeforeunload = (event) => {
      if (showExitPrompt) {
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = "";
        }
        UnLoadWindow();
        return "";
      }
    };
  };

  window.onload = function () {
    initBeforeUnLoad(shopId);
  };

  // Re-Initialize the onbeforeunload event listener
  useEffect(() => {
    initBeforeUnLoad(shopId);
  }, [shopId]);

  return (
    <>
      <BreadCrumb
        title="Requested Product List"
        subTitle={`${productData?.data?.products?.totalElements} Product(s)`}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
            <AddBtn
            onClick={() => {
              navigate(
                inShop
                  ? `/shops/${getShopId}/request/products/create`
                  : `/request-products/create`
              );
            }}
          />,
        ]}
      />

      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} className={styles.formInline}>
            <Form.Item name="search">
              <AutoComplete
                style={{ width: 300 }}
                onSearch={(e) => handleSearch(e, "product")}
                onSelect={(val: { toString: () => SetStateAction<string> }) =>
                  setKey(val.toString())
                }
                options={productOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  productOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  placeholder="Search by Name"
                  onSearch={(val) => setKey(val)}
                  enterButton
                  loading={productOptions.loading}
                />
              </AutoComplete>
            </Form.Item>

            <Form.Item name="category_search">
              <TreeSelectTwo setCategoryId={setCategoryId} />
            </Form.Item>

            <Form.Item name="brand_search">
              <Select
                allowClear
                showSearch
                placeholder="Filter by Brand"
                optionFilterProp="children"
                onChange={(val) => setBrandId(val)}
                onSearch={(e) => handleSearch(e, "brand")}
                filterOption={() => {
                  return true;
                }}
                options={brandOptions?.list}
              ></Select>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {confirmLoading && <LoaderFull />}
              {productData?.data?.products?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead>
                    <tr>
                      <th scope="col">Product</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {productData?.data?.products?.length ? (
                      productData?.data?.products?.map(
                        (product: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                          >
                            <td className="flex items-center gap-2">
                              <a
                                href={liveUrl + `products/` + product?.productSlug}
                                target="_blank"
                                rel="noreferer noopener"
                              >
                                <Avatar
                                  size={45}
                                  src={product?.productImage}
                                  shape="square"
                                />
                              </a>
                              <div className="flex flex-col">
                                <span className="font-medium text-gray-500">
                                  {product?.productName?.slice(0, 50)}
                                </span>
                                {product?.barCode && (
                                  <span className="font-light text-gray-500 text-xs flex items-center gap-1">
                                    <BarcodeOutlined /> {product?.barCode}
                                  </span>
                                )}
                              </div>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 border p-1 px-4 bg-gray-100 rounded-full ">
                                {product?.status}
                              </span>
                            </td>

                            <td>
                              <div className="flex flex-grow">
                                <Link
                                  title="Edit Product"
                                  to={
                                    inShop
                                      ? `/shops/${getShopId}/request-products/${product?.slug}/details`
                                      : `/request-products/${product?.slug}/details`
                                  }
                                  className="py-2 px-4 rounded-full mr-1 leading-none bg-white border hover:bg-blue-100 hover:text-gray-600 flex items-center"
                                >
                                  <EyeOutlined />
                                </Link>
                                <Link
                                  title="Edit Product"
                                  to={
                                    inShop
                                      ? `/shops/${getShopId}/request-products/${product?.slug}/edit`
                                      : `/products/${product?.slug}/edit`
                                  }
                                  className="py-2 px-4 rounded-full mr-1 leading-none bg-white border hover:bg-blue-100 hover:text-gray-600 flex items-center"
                                > 
                                  <EditOutlined />
                                </Link>
                                {/* <Button
                                  shape="round"
                                  type="primary"
                                  danger
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setSelectedProductForEdit(product);
                                  }}
                                >
                                  <FileDoneOutlined />
                                </Button>{" "} */}
                              </div>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...productData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>
    </>
  );
};

export default RequestedProductList;
